import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ValidateComponent } from './pages/validate/validate.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        title: 'home | Madero Cashback',
        component: HomeComponent,
    },
    {
        path: 'not-found',
        title: 'Página não encontrada | Madero Cashback',
        component: NotFoundComponent,
    },
    {
        path: 'validate',
        title: 'Validar email | Madero Cashback',
        component: ValidateComponent,
    },
    {
        path: '**',
        redirectTo: 'not-found',
        pathMatch: 'full',
    }
];
