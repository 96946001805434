import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PartnerService } from '../../core/services/partner.service';
import { Partner } from '../../shared/interfaces/partner.interface';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ActivatedRoute, Router } from '@angular/router';
import { cpfValidator } from '../../core/util/cpf-validator';
import { extendedEmailValidator } from '../../core/util/email-validator';

function binValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (!value || !/^\d{6}$|^\d{8}$/.test(value)) {
    return { invalidBin: true };
  }
  return null; 
}

@Component({
  selector: 'app-home',
  standalone: true, 
  providers: [
    provideNgxMask()
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    NgxMaskDirective
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit{
  private readonly urlGoogle = 'https://play.google.com/store/apps/details?id=br.com.alphacode.madero&hl=pt_BR&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
  private readonly urlApple = 'https://apps.apple.com/br/app/grupo-madero-app/id1579688707?l=en';
  private readonly urPrivacy = 'https://madero.blob.core.windows.net/fidelidade/Documentos/Politica_de_Privacidade_Madero_Cashback.pdf';
  private readonly urTerms = 'https://madero.blob.core.windows.net/fidelidade/Documentos/Regulamento_Madero_Cashback.pdf';

  form!: FormGroup;
  loading: boolean = false;
  imagePartner = ''
  partnerActive!: Partner;
  binActive = false;
  classRed = false;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private partnerService: PartnerService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.onBuildForm();
    this.onMonitoringRouter();
  }

  onMonitoringRouter(): void{
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams['partner']) {
      this.onGetPartner(queryParams['partner']);
    } else {
      this.router.navigate(['/not-found']);
    }
  }

  onGetPartner(partnerId: string): void {
    if(partnerId == '4mN3kZO14r') this.classRed = true;
    this.partnerService.getPartner(partnerId).subscribe({
      next: (response) => {
        this.imagePartner = `${response.value.logoBase64}`; 
        this.partnerActive = response.value;
        response.value.fields.map((field: any) => {
          if(field.id === '005'){
            this.binActive = true
          }
        })
        this.onVerifyBIN()
        if(!response?.value?.benefitAvailable){
          this.router.navigate(['/not-found']);
        }
      },
      error: (err) => {
        console.log('Erro ao buscar a empresa!');
        this.router.navigate(['/not-found']);
      },
    })
  }

  onBuildForm(): void {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      mail: [null, [Validators.required, Validators.email, extendedEmailValidator()]],
      cpf: [null, [Validators.required, cpfValidator]],
      phone: [null, Validators.required],
      terms: [false, Validators.requiredTrue]
    });
    this.onVerifyBIN();
  }
  
  onVerifyBIN(): void {
    if (this.binActive) {
      this.form.addControl('bin', this.fb.control(null, [Validators.required, binValidator]));
    } else if (this.form.get('bin')) {
      this.form.removeControl('bin');
    }
  }

  onSubmit(): void {
    this.loading = true;
    const formResult = this.form.getRawValue();

    const body = {
      cpf: formResult.cpf,
      name: formResult.name,
      phone: formResult.phone,
      email: formResult.mail,
      companyCode: this.partnerActive.code,
      password: formResult.bin ?? null
    }

    this.partnerService.registerConsumer(body).subscribe({
      next: (user) => {
        this.snackBar.open(
          'Cadastro realizado com sucesso!',
          'fechar',
        );
        this.form.reset({
          name: null,
          mail: null,
          cpf: null,
          phone: null,
          bin: null,
          terms: false
        }, { emitEvent: false });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      error: (err) => {
        this.loading = false;
        this.snackBar.open(
          'Houve um problema ao tentar enviar o cadastro!',
          'fechar',
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  scrollToBotton(): void{
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  onRedirect(nameLink: string): void{
    switch (nameLink) {
      case 'google':
        window.open(this.urlGoogle, '_blank')
        break;
      case 'apple':
        window.open(this.urlApple, '_blank')
        break;
      case 'terms':
        window.open(this.urTerms, '_blank')
        break;
      case 'privacy':
        window.open(this.urPrivacy, '_blank')
        break;
    
      default:
        break;
    }
  }

  validateNumberInput(event: KeyboardEvent): void {
    const regex = /^[0-9]$/;
    const key = event.key;
  
    if (!regex.test(key)) {
      event.preventDefault();
    }
  }
}
