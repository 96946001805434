import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PartnerService } from '../../core/services/partner.service';
import { Partner } from '../../shared/interfaces/partner.interface';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true, 
  providers: [
    provideNgxMask()
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    NgxMaskDirective
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit{
  private readonly urlGoogle = 'https://play.google.com/store/apps/details?id=br.com.alphacode.madero&hl=pt_BR&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
  private readonly urlApple = 'https://apps.apple.com/br/app/grupo-madero-app/id1579688707?l=en';
  private readonly urPrivacy = 'https://madero.blob.core.windows.net/fidelidade/Documentos/Politica_de_Privacidade_Madero_Cashback.pdf';
  private readonly urTerms = 'https://madero.blob.core.windows.net/fidelidade/Documentos/Regulamento_Madero_Cashback.pdf';

  form!: FormGroup;
  loading: boolean = false;
  imagePartner = ''
  partnerActive!: Partner;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private partnerService: PartnerService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.onBuildForm();
    this.onMonitoringRouter();
  }

  onMonitoringRouter(): void{
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams['partner']) {
      this.onGetPartner(queryParams['partner']);
    } else {
      this.router.navigate(['/not-found']);
    }

  }

  onGetPartner(partnerId: string): void {

    this.partnerService.getPartner(partnerId).subscribe({
      next: (response) => {
        console.log('response: ', response);
        this.imagePartner = `${response.value.logoBase64}`; 
        this.partnerActive = response.value;
      },
      error: (err) => {
        console.log('Erro ao buscar a empresa!');
        this.router.navigate(['/not-found']);
      },
    })
  }

  onBuildForm(): void {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      mail: [null, Validators.required],
      cpf: [null, Validators.required],
      phone: [null, Validators.required],
      terms: [false, Validators.required],
    });
  }

  onSubmit(): void {
    this.loading = true;
    const formResult = this.form.getRawValue();

    const body = {
      cpf: formResult.cpf,
      name: formResult.name,
      phone: formResult.phone,
      email: formResult.mail,
      companyCode: this.partnerActive.code,
      password: ''
    }

    this.partnerService.registerConsumer(body).subscribe({
      next: (user) => {
        this.snackBar.open(
          'Cadastro realizado com sucesso!',
          'fechar',
        );
        this.form.reset({
          name: null,
          mail: null,
          cpf: null,
          phone: null,
          terms: false
        }, { emitEvent: false });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      error: (err) => {
        this.loading = false;
        this.snackBar.open(
          'Houve um problema ao tentar enviar o cadastro!',
          'fechar',
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  scrollToBotton(): void{
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  onRedirect(nameLink: string): void{
    switch (nameLink) {
      case 'google':
        window.open(this.urlGoogle, '_blank')
        break;
      case 'apple':
        window.open(this.urlApple, '_blank')
        break;
      case 'terms':
        window.open(this.urTerms, '_blank')
        break;
      case 'privacy':
        window.open(this.urPrivacy, '_blank')
        break;
    
      default:
        break;
    }
  }
}
