import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../../core/services/partner.service';

@Component({
  selector: 'app-validate',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './validate.component.html',
  styleUrl: './validate.component.scss'
})
export class ValidateComponent implements OnInit{
  isLoader = true;
  isSucess = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private partnerService: PartnerService,){}

  ngOnInit(): void {
    this.onMonitoringRouter();
  }

  onMonitoringRouter(): void{
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams['hash']) {
      this.onValidateMail(queryParams['hash']);
    } else {
      this.isSucess = false;
      this.isLoader = false;
    }
  }

  onValidateMail(validationHash: string): void{
    const body = {
      validationHash: validationHash
    };

    this.partnerService.validateMail(body).subscribe({
      next: (response) => {
        this.isSucess = true;
        this.isLoader = false;
      },
      error: (err) => {
        this.isSucess = false;
        this.isLoader = false;
      },
    })

  }
}
