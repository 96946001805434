import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function extendedEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (!email) {
      return null;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(email);
    
    return isValid ? null : { invalidEmail: true };
  };
}
