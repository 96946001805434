import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environments';
import { PartnerResponse } from '../../shared/interfaces/partner.interface';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private http: HttpClient) { }

  getPartner(idCompany: string): Observable<PartnerResponse> {
    return this.http.get<PartnerResponse>(
      `${environment.CASHBACK_API}Company?code=${idCompany}`,
    )
  }

  registerConsumer(body: any): Observable<any> {
    return this.http.post<any>(
      `${environment.CASHBACK_API}ConsumerBenefit`,
      body
    )
  }

  validateMail(body: any): Observable<any> {
    return this.http.post<any>(
      `${environment.CASHBACK_API}ConsumerBenefit/validate`,
      body
    )
  }
}
