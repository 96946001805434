<div class="c-navbar">
    <img src="../../../assets/logo_negativa.svg" alt="">
</div>
<div class="c-hero" [ngClass]="classRed ? 'red-bg' : ''">
    <div class="c-hero__content">
        <h1>Parabéns!</h1>
        <p>Você está prestes a se tornar membro <b>Black</b> do Madero Cashback</p>
        <button mat-flat-button class="c-btn  c-btn--primary lg" (click)="scrollToBotton()">Faça parte!</button>
    </div>
    <div class="c-hero__content">
        <img src="../../../assets/cards.png" class="floating" alt="">
    </div>
</div>
<div class="c-partner">
    <h1>Parceria entre</h1> <img src="../../../assets/madero-partner.svg" alt=""> <h1>e</h1> 
    @if (imagePartner) {
        <img [src]="imagePartner" alt="">
    }
</div>
<div class="c-partner-description">
    <div class="c-partner-description__content">
        <img src="../../../assets/hand-phone.png" alt="">
    </div>
    <div class="c-partner-description__content">
        <p>Ao se cadastrar através da empresa <b>{{ partnerActive?.name || 'Madero' }}</b>, você poderá ser <b>Madero Cashback Black.</b></p>
        <p>Com isso, você acumulará um <b>saldo</b> de <b>10%</b> em cada compra realizada nos <b>nossos restaurantes ou APP</b>, que poderá ser utilizado nas suas próximas refeições.</p>
        @if( partnerActive?.benefitValue !== 0){
            <p>Além disso, você já ganha um <b>saldo de {{ 'R$ ' + partnerActive?.benefitValue +',00' }}</b> para utilizar em suas futuras compras.</p>
        }
    </div>
</div>
<div class="c-steps">
    <h1>COMO FUNCIONA?</h1>
    <p>O Madero Cashback é um programa de fidelidade que fornece créditos retornáveis para consumo no Madero. Eles são acumulados durante uma ou mais visitas nos restaurantes participantes e, quando resgatados, podem ser utilizados como desconto sobre o valor total na sua próxima consumação.</p>
    <h1 class="c-steps__sub">Veja como é fácil participar: </h1>
    <div class="c-steps__steps">
        <div class="c-steps__steps--item">
            <img src="../../../assets/step/step1.png" alt="">
            <h1>CADASTRE-SE</h1>
            <p>Preencha o formulário abaixo e cadastre-se no App Grupo Madero para se tornar membro do Programa Fidelidade!</p>
        </div>
        <div class="c-steps__steps--item">
            <img src="../../../assets/step/step2.png" alt="">
            <h1>ACUMULE</h1>
            <p>Acumule créditos consumindo em qualquer um dos restaurantes participantes informando seu CPF na nota fiscal.</p>
        </div>
        <div class="c-steps__steps--item">
            <img src="../../../assets/step/step3.png" alt="">
            <h1>TROQUE</h1>
            <p>Acesse o aplicativo do grupo para verificar seu saldo de créditos e resgatá-los como desconto na sua próxima compra.</p>
        </div>
    </div>
</div>
<div class="c-footer">
    <div class="c-footer__item">
        <h1>Preencha suas informações para continuar</h1>
        <form class="c-form"
            appearance="outline"
            [formGroup]="form"
            (ngSubmit)="onSubmit()">

            <mat-form-field appearance="outline" class="c-form__field">
                <mat-label>Nome</mat-label>
                <input
                    matInput
                    placeholder="Nome..."
                    formControlName="name"
                    placeholder="Insira seu Nome"
                />    
                @if (form.get('name')?.hasError('required')) {
                    <mat-error>Insira seu Nome</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="outline" class="c-form__field">
                <mat-label>E-mail</mat-label>
                <input
                    matInput
                    placeholder="E-mail..."
                    formControlName="mail"
                    placeholder="Insira seu E-mail"
                />  
                @if (form.get('mail')?.hasError('required')) {
                    <mat-error>Insira seu e-mail</mat-error>
                }
                @if (form.get('mail')?.hasError('email')) {
                    <mat-error>Coloque um e-mail válido</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="outline" class="c-form__field">
                <mat-label>CPF</mat-label>
                <input matInput 
                    mask="000.000.000-00" 
                    placeholder="CPF..."
                    formControlName="cpf" />
                @if (form.get('cpf')?.hasError('required')) {
                <mat-error>Insira seu cpf</mat-error>
                }
                @if (form.get('cpf')?.hasError('invalidCpf')) {
                <mat-error>CPF inválido</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="outline" class="c-form__field">
                <mat-label>Telefone</mat-label>
                <input
                    matInput
                    placeholder="Telefone..."
                    formControlName="phone"
                    placeholder="Insira seu Telefone"
                    mask="(00) 00000-0000"
                />    
                @if (form.get('phone')?.hasError('required')) {
                    <mat-error>Insira seu Telefone</mat-error>
                }
            </mat-form-field>
            @if(binActive){
                <mat-form-field appearance="outline" class="c-form__field">
                    <mat-label>BIN</mat-label>
                    <input
                        matInput
                        placeholder="BIN..."
                        formControlName="bin"
                        placeholder="Insira BIN do cartão"
                        [pattern]="'\\d{6}|\\d{8}'"
                        maxlength="8"
                        inputmode="numeric"
                        (keypress)="validateNumberInput($event)"
                    />    
                    @if (form.get('bin')?.hasError('required')) {
                        <mat-error>Insira seu BIN</mat-error>
                    }
                    @else if (form.get('bin')?.hasError('invalidBin')) {
                        <mat-error>O BIN deve ter 6 ou 8 caracteres</mat-error>
                    }
                </mat-form-field>
            }
            <mat-checkbox color="primary" class="example-margin" formControlName="terms">Aceito os termos do programa de fidelidade Madero Cashback</mat-checkbox>
            <button
                mat-flat-button
                class="c-btn c-btn--primary"
                type="submit"
                [disabled]="!form.valid"
            >
                @if (loading) {
                <div class="c-btn-spinner">
                    <mat-spinner class="c-btn--spinner"></mat-spinner>
                    <span>Carregando...</span>
                </div>
                } @else {
                    <span>Cadastrar</span>
                }
            </button>
            <div class="c-form__footer">
                <p>Madero Cashback é o Programa de Fidelidade do Grupo Madero.</p>
                <p class="c-form__footer--terms">
                    <span (click)="onRedirect('privacy')" class="c-cursor">Politicas de Privacidade</span> | 
                    <span (click)="onRedirect('terms')" class="c-cursor">Termos de uso</span>                    
                </p>
            </div>
        </form>
    </div>
    <div class="c-footer__item dark">
        <div class="c-flex">
            <img src="../../../assets/footer-logo.png" alt="">
            <h4>Baixe o app e aproveite suas vantagens</h4>
            <div class="c-stores">
                <div class="c-stores__item" (click)="onRedirect('apple')">
                    <img src="../../../assets/store-apple.png" class="c-cursor" alt="">
                </div>
                <div class="c-stores__item" (click)="onRedirect('google')">
                    <img src="../../../assets/store-google.png" class="c-cursor" alt="">
                </div>
            </div>
        </div>
    </div>
</div>