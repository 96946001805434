<div class="c-navbar">
    <img src="../../../assets/logo_negativa.svg" alt="">
</div>
<div class="c-content">
    @if (!isLoader) {
        @if (isSucess) {
            <div class="success-animation">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
            <h1 class="c-content__title">Tudo pronto! Seu email foi confirmado.</h1>
            <h3 class="c-content__subtitle">Agora é só explorar todas as vantagens que preparamos para você. Qualquer dúvida, é só nos chamar!</h3>  
        }@else {
            <mat-icon class="c-content__icon">warning</mat-icon>
            <h1 class="c-content__title">Ops, tivemos um problema ao validar o email</h1>
            <h3 class="c-content__subtitle">Acesso novamente o link e repita o processo.</h3>        
        }
    }@else {
        <div class="c-spinner">
            <mat-spinner></mat-spinner>
        </div>
    }
</div>
